/**@jsx jsx */

import PropTypes from "prop-types"
import { Grid, jsx } from "theme-ui"

function Address({ companyName, streetAddress, zipCodeCity }) {
  return (
    <Grid
      sx={{
        fontSize: [0, 1],
        color: "white",
        opacity: 0.6,
      }}
    >
      {companyName ? <span>{companyName}</span> : null}
      {streetAddress ? <span>{streetAddress}</span> : null}
      {zipCodeCity ? <span>{zipCodeCity}</span> : null}
    </Grid>
  )
}

Address.propTypes = {
  companyName: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired,
  zipCodeCity: PropTypes.string.isRequired,
}

export default Address
