import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { createContext, useMemo } from "react"

export const Context = createContext(null)

export const fragment = graphql`
  fragment LogoFields on ContentfulAsset {
    title
    file {
      url
      fileName
    }
  }
`

function SettingsContext({ children, locale }) {
  const { settings } = useStaticQuery(graphql`
    query SettingsQuery {
      settings: allContentfulSiteSettings {
        nodes {
          companyName
          cookieNoticeText {
            json
          }
          cookieNoticeTitle
          copyrightTemplate
          emailAddress
          facebookLink
          instagramLink
          labelContact
          linkedInLink
          logoSmall {
            ...LogoFields
          }
          logoLarge {
            ...LogoFields
          }
          mapLink
          node_locale
          phoneNumber
          phoneNumberLabel
          skipToContentLabel
          streetAddress
          twitterUsername
          zipCodeCity
        }
      }
    }
  `)

  const localizedSettings = useMemo(
    () => settings?.nodes?.find(node => node.node_locale === locale),
    [settings, locale]
  )
  return (
    <Context.Provider value={localizedSettings}>{children}</Context.Provider>
  )
}

SettingsContext.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
}

export default SettingsContext
