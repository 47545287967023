/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, jsx } from "theme-ui"

import ErrorBoundary from "@components/ErrorBoundary"
import Footer from "@components/Footer"
import Header from "@components/Header"
import SkipNavContent from "@components/SkipNav/Content"

function BaseLayout({ children }) {
  return (
    <ErrorBoundary>
      <Flex sx={{ flexDirection: "column", minHeight: "100vh" }}>
        <Header />
        <SkipNavContent>
          <main>{children}</main>
        </SkipNavContent>
        <Footer />
      </Flex>
    </ErrorBoundary>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BaseLayout
