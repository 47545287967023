export default {
  bbox: {
    boxSizing: "border-box",
  },
  reset: {
    margin: 0,
    padding: 0,
    variant: "bbox",
  },
  link: {
    variant: "reset",
    cursor: "pointer",
    textDecoration: "none",
    outline: "none",
  },
  buttons: {
    borderRadius: "100px",
    borderStyle: "solid",
    borderWidth: "2px",
    cursor: "pointer",
    display: "flex",
    fontFamily: "body",
    fontSize: 2,
    fontWeight: "bold",
    justifyContent: "center",
    justifyItems: "center",
    lineHeight: "1",
    paddingX: 10,
    paddingY: 6,
    textAlign: "center",
    textDecoration: "none",
  },
  button: {
    primary: {
      backgroundColor: "black",
      borderColor: "black",
      color: "white",
      variant: "buttons",

      ":hover, :active": {
        backgroundColor: "primary",
        borderColor: "primary",
        color: "text",
      },

      ":disabled": {
        backgroundColor: "disabled",
        borderColor: "disabled",
        color: "white",
      },
    },
    secondary: {
      backgroundColor: "white",
      borderColor: "black",
      color: "text",
      variant: "buttons",

      ":hover, :active": {
        borderColor: "primary",
        color: "primary",
      },

      ":disabled": {
        borderColor: "disabled",
        color: "disabled",
      },
    },
    tertiary: {
      backgroundColor: "primary",
      borderColor: "primary",
      color: "text",
      variant: "buttons",

      ":hover, :active": {
        backgroundColor: "primary",
      },

      ":disabled": {
        backgroundColor: "disabled",
        borderColor: "disabled",
        color: "white",
      },
    },
  },
  heading: {
    variant: "reset",
    color: "text",
    fontFamily: "heading",
    fontWeight: "bold",
    letterSpacing: "0px",
  },
  caption: {
    color: "text",
    fontFamily: "heading",
    fontSize: [1, 3],
    fontWeight: "bold",
    letterSpacing: "0px",
    lineHeight: "1",
    variant: "reset",
  },
  container: {
    mx: "auto",
    maxWidth: 1140,
    width: "100%",
  },
  containerSpacing: {
    px: 6,
    py: [15, 18],
  },
  text: {
    small: {
      variant: "reset",
      color: "text",
      fontFamily: "body",
      fontSize: 0,
      fontWeight: "regular",
      lineHeight: "1.2",
    },
    large: {
      variant: "reset",
      color: "grey.dark",
      fontFamily: "body",
      fontSize: [1, 4],
      fontWeight: "regular",
      lineHeight: "1.4",
      maxWidth: 850,
      opacity: "0.9",

      a: {
        color: "primary",
        fontWeight: "bold",
        textDecoration: "underline",
      },
    },
    preamble: {
      variant: "reset",
      color: "text",
      fontFamily: "body",
      fontSize: [3, 4],
      fontWeight: "regular",
      lineHeight: "1.4",
      maxWidth: 850,

      a: {
        color: "primary",
        fontWeight: "bold",
        textDecoration: "underline",
      },
    },
  },
  richtext: {
    b: {
      color: "inherit",
    },
    i: {
      color: "inherit",
    },
    p: {
      variant: "reset",
      color: "grey.dark",
      fontSize: [2, 3],
      lineHeight: 1.4,

      a: {
        color: "primary",
        fontWeight: "bold",
        textDecoration: "underline",
      },
    },
    h1: {
      variant: "heading",
      textAlign: "center",
      fontSize: [4, 5],
      mb: 7,
    },
    h2: {
      variant: "heading",
      textAlign: "center",
      fontSize: [4, 5],
      mb: 7,
    },
    h3: {
      variant: "heading",
      textAlign: "center",
      fontSize: [1, 3],
      mb: 5,
    },
    h4: {
      variant: "heading",
      textAlign: "center",
      fontSize: [1, 3],
      mb: 5,
    },
    h5: {
      variant: "heading",
      textAlign: "center",
      mb: 5,
    },
    h6: {
      variant: "text.small",
      textAlign: "center",
      mb: 5,
    },
    ul: {
      variant: "reset",
      pl: 5,
    },
    ol: {
      variant: "reset",
      pl: 5,
    },
    li: {
      variant: "reset",
      color: "grey.dark",
      fontSize: [0, 3],
      listStyle: "none",
      position: "relative",

      "::before": {
        content: '"•"',
        fontSize: "40px",
        left: "-20px",
        position: "absolute",
        top: "-4px",
      },

      "> *": {
        fontSize: [0, 3],
      },

      "& + &": {
        mt: 3,
      },
    },
    blockquote: {
      variant: "reset",
      "> p": {
        color: "text",
        textAlign: "center",
      },
    },
    a: {
      color: "primary",
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
}
