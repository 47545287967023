import { useCallback } from "react"

import useHandleKeyPress from "./useHandleKeyPress"

const ESC = 27

export default function useCloseOnEsc(onEscape) {
  const handleOnEsc = useCallback(
    event => {
      const { keyCode } = event
      if (keyCode === ESC) {
        onEscape()
      }
    },
    [onEscape]
  )
  useHandleKeyPress(handleOnEsc)
}
