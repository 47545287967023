// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-en-cookies-js": () => import("./../../../src/pages/en/cookies.js" /* webpackChunkName: "component---src-pages-en-cookies-js" */),
  "component---src-pages-sv-cookies-js": () => import("./../../../src/pages/sv/cookies.js" /* webpackChunkName: "component---src-pages-sv-cookies-js" */),
  "component---src-templates-newspage-template-js": () => import("./../../../src/templates/NewspageTemplate.js" /* webpackChunkName: "component---src-templates-newspage-template-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/NotFoundPage.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

