export default {
  background: "#FFFFFF",
  black: "#131313",
  disabled: "#E6E6E6",
  grey: {
    dark: "#717171",
    darker: "#666666",
    light: "#E6E6E6",
    lighter: "#F7F7F7",
  },
  primary: "#F9B013",
  primaryLight: "#F9D682",
  secondary: "#8FBBAF",
  tertiary: "#B486B9",
  text: "#131313",
  white: "#FFFFFF",
}
