/** @jsx jsx */
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { Fragment, useMemo, useState } from "react"
import { jsx } from "theme-ui"

import Border from "@components/Border"
import SkipNavLink from "@components/SkipNav/Link"

import Logo from "./Logo"
import MainNav from "./MainNav"

function Header() {
  const [isBorderVisible, setIsBorderVisible] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y !== 0
      if (isShow !== isBorderVisible) {
        setIsBorderVisible(isShow)
      }
    },
    [isBorderVisible],
    false,
    false
  )

  return useMemo(
    () => (
      <Fragment>
        <header
          sx={{
            alignItems: "center",
            backgroundColor: "white",
            display: "flex",
            paddingX: [6, null, null, 13],
            paddingY: [2, 6],
            position: "sticky",
            top: 0,
            zIndex: 99,
          }}
        >
          <div sx={{ variant: "container" }}>
            <SkipNavLink />
            <Logo />
          </div>
          <nav
            sx={{
              variant: "container",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <MainNav />
          </nav>
          {/* {isBorderVisible && ( */}
          <div
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              opacity: isBorderVisible ? 1 : 0,
              transition: "0.3s opacity ease-in-out",
            }}
          >
            <Border />
          </div>
          {/* )} */}
        </header>
      </Fragment>
    ),
    [isBorderVisible]
  )
}

export default Header
