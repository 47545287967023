import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import LocaleContext from "@context/LocaleContext"
import SettingsContext from "@context/SettingsContext"
import BaseLayout from "@layout/BaseLayout"

export function wrapPageElement({ element, props: { pageContext } }) {
  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: pageContext?.locale ?? pageContext?.node_locale }} />
      <SettingsContext locale={pageContext?.locale ?? pageContext?.node_locale}>
        <LocaleContext.Provider value={pageContext?.locale ?? pageContext?.node_locale}>
          <BaseLayout>{element}</BaseLayout>
        </LocaleContext.Provider>
      </SettingsContext>
    </Fragment>
  )
}
