/** @jsx jsx */
import useSettingsContext from "@src/hooks/useSettingsContext"
import { jsx } from "theme-ui"

const currentYear = new Date().getFullYear()
const fallbackTemplate = "Copyright {year} Brocc AB"

function Copyright() {
  const { copyrightTemplate } = useSettingsContext()
  return (
    <span
      sx={{
        color: "white",
        textAlign: "center",
        fontSize: ["12px"], // Special case
      }}
    >
      {(copyrightTemplate ? copyrightTemplate : fallbackTemplate).replace(
        "{year}",
        currentYear
      )} 
    </span>
  )
}

export default Copyright
