/**@jsx jsx */

import PropTypes from "prop-types"
import { Grid, jsx, Styled } from "theme-ui"

import NavLink from "@components/NavLink"
import useLocaleContext from "@src/hooks/useLocaleContext"

const styles = {
  mainLink: {
    variant: "link",
    color: "white",
    fontSize: [2, 3],
    fontWeight: "bold",
    cursor: "unset",
    "&.active": {
      color: "primary",
    },
  },
  link: {
    variant: "link",
    fontSize: [0, 1],
    color: "white",
    opacity: 0.6,
    marginBottom: "4px",
    lineHeight: "1.3",
    "&.active, :hover, :focus": {
      opacity: 1,
    },
  },
}

function Section({ href, slug, title, links, link }) {
  const locale = useLocaleContext()
  return (
    <Grid gap={6}>
      <p sx={styles.mainLink}>{title}</p>

      {links ? (
        <Styled.ul
          sx={{
            display: "grid",
            gap: 5,
            gridTemplateColumns: ["1fr 1fr", "1fr"],
          }}
        >
          {links.map(({ href, slug, title, id }) => {
            return (
              <Styled.li key={id}>
                {href.includes("http") ? (
                  <a
                    href={href}
                    rel="noreferrer noopener"
                    sx={styles.link}
                    target="_blank"
                  >
                    {title}
                  </a>
                ) : (
                  <NavLink sx={styles.link} to={slug || href}>
                    {title}
                  </NavLink>
                )}
              </Styled.li>
            )
          })}
        </Styled.ul>
      ) : null}
    </Grid>
  )
}

Section.defaultProps = {
  href: undefined,
  links: null,
  slug: undefined,
}

Section.propTypes = {
  href: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Section
