/** @jsx jsx */

import { Grid, jsx } from "theme-ui"

import Copyright from "@components/Footer/Copyright"
import Navigation from "@components/Footer/Navigation"
import LogoSmall from "@components/LogoSmall"

function Footer() {
  return (
    <footer sx={{ background: "black" }}>
      <Grid gap={[10, 12]} sx={{ pt: 13, pb: 8, px: 6, variant: "container" }}>
        <Grid columns={[null, "1fr"]} gap={10}>
          <Navigation />
        </Grid>
        <div
          sx={{
            height: "1px",
            backgroundColor: "grey.darker",
            width: "100%",
          }}
        />
        <Grid gap={5} sx={{ justifyContent: "center", justifyItems: "center" }}>
          <LogoSmall />
          <Copyright />
        </Grid>
      </Grid>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer
