/** @jsx jsx */
import { graphql, Link, useStaticQuery } from "gatsby"
import { useMemo } from "react"
import { jsx } from "theme-ui"

import useLocaleContext from "@hooks/useLocaleContext"

export default function Logo() {
  const locale = useLocaleContext()

  const {
    allContentfulPage: { frontpages },
    contentfulMainNavigation: { logo },
  } = useStaticQuery(
    graphql`
      query getLogo {
        allContentfulPage(filter: { isFrontpage: { eq: true } }) {
          frontpages: nodes {
            node_locale
            slug
          }
        }
        contentfulMainNavigation {
          logo {
            altText: title
            file {
              url
            }
          }
        }
      }
    `
  )

  const slug =
    useMemo(() => frontpages.find(fp => fp.node_locale === locale)?.slug, [
      locale,
      frontpages,
    ]) || "/"

  return (
    <Link sx={{ display: "inline-block", outline: "none" }} to={slug}>
      <img
        alt={logo?.altText}
        src={logo?.file?.url}
        sx={{
          height: ["35px", "38px"],
          width: "auto",
        }}
      />
    </Link>
  )
}
