export default {
  h1: {
    variant: "heading",
    fontSize: [6, null, 8],
    lineHeight: "1.1",
  },
  h2: {
    variant: "heading",
    fontSize: [6, 7],
    lineHeight: "1",
  },
  h3: {
    variant: "heading",
    fontSize: [4, 5],
    lineHeight: "1.3",
  },
  h4: {
    variant: "heading",
    fontSize: [2, 3],
    lineHeight: "1.2",
  },
  p: {
    variant: "reset",
    color: "grey.dark",
    fontFamily: "body",
    fontSize: [1, 3],
    lineHeight: "1.4",
  },
  ul: {
    variant: "reset",
    listStyle: "none",
  },
  ol: {
    variant: "reset",
  },
  li: {
    variant: "reset",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  root: {
    backgroundColor: "background",
    color: "text",
    fontFamily: "body",
    fontWeight: "regular",
    lineHeight: "1",
    fontSize: 0,
    WebkitFontSmoothing: "antialiased", // Use??
    "*::selection": {
      backgroundColor: "primary",
      color: "white",
    },
  },
  spinner: {
    color: "black",
  },
}
