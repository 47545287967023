/** @jsx jsx */
import { jsx } from "theme-ui"

export default function Border() {
  return (
    <div
      sx={{
        backgroundColor: "grey.light",
        height: "2px",
        width: "100%",
      }}
    />
  )
}
