/** @jsx jsx */
import PropTypes from "prop-types"
import { forwardRef, useCallback, useEffect, useRef } from "react"
import { Flex, jsx } from "theme-ui"

import NavLink from "@components/NavLink"
import useCloseOnEsc from "@hooks/useCloseOnEsc"

import LoginButton from "./LoginButton"

const Sidemenu = forwardRef(({ closeMenu, isOpen, menu }, ref) => {
  useCloseOnEsc(closeMenu)
  const listRef = useRef(null)

  const handleClickOutside = useCallback(
    evt => {
      if (isOpen && !evt?.target?.contains(ref.current)) {
        closeMenu()
      }
    },
    [isOpen, closeMenu, ref]
  )

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    if (isOpen) {
      listRef.current?.firstChild?.focus()
    }
  }, [isOpen, listRef])

  return (
    <Flex
      aria-hidden="true"
      ref={ref}
      sx={{
        bottom: 0,
        position: "fixed",
        right: isOpen ? 0 : "-100%",
        top: 0,
        transition: "right 300ms ease",
        visibility: isOpen ? "visible" : "hidden",
        width: "100%",
        zIndex: 98,
      }}
    >
      <div
        sx={{
          backgroundColor: "rgba(0,0,0,0.1)",
          display: ["none", null, "block"],
          height: "100%",
          width: "50%",
        }}
      />
      <Flex
        sx={{
          alignItems: "center",
          backgroundColor: "black",
          height: "100%",
          justifyContent: "flex-end",
          position: "relative",
          pr: 13,
          width: ["100%", null, "50%"],
        }}
      >
        <Flex
          ref={listRef}
          sx={{
            alignItems: "flex-end",
            flexDirection: "column",
            maxHeight: "80vh",
            overflow: "auto",
            py: 4,
          }}
        >
          {Array.isArray(menu) && menu.length
            ? menu.map(({ href, id, slug, title }) => (
                <NavLink
                  href={href}
                  key={id}
                  partiallyActive
                  sx={{
                    variant: "link",
                    color: "white",
                    fontSize: [6, null, 7],
                    fontWeight: "bold",
                    outline: "none",

                    "& + &": {
                      mt: 6,
                    },

                    "&.active": {
                      color: "primary",
                    },
                  }}
                  to={slug}
                >
                  {title}
                </NavLink>
              ))
            : null}
        </Flex>
        <div
          sx={{
            bottom: "40px",
            display: [null, null, "none"],
            left: "50%",
            position: "absolute",
            transform: "translateX(-50%)",
          }}
        >
          <LoginButton />
        </div>
      </Flex>
    </Flex>
  )
})

Sidemenu.displayName = "Sidemenu"

Sidemenu.defaultProps = {
  menu: null,
}

Sidemenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  menu: PropTypes.array,
}

export default Sidemenu
