/** @jsx jsx */
import "@reach/skip-nav/styles.css"

import { SkipNavLink as ReachSkipNavLink } from "@reach/skip-nav"
import { jsx } from "theme-ui"

import useSettingsContext from "@hooks/useSettingsContext"

export default function SkipNavLink() {
  // const { skipToContentLabel } = useSettingsContext()

  return (
    <ReachSkipNavLink
      contentId="main-content"
      sx={{
        backgroundColor: "background",
        color: "text",
        fontWeight: "bold",
        textDecoration: "none",
        zIndex: 10000,
      }}
    >
      {/* {skipToContentLabel ? skipToContentLabel : "Hoppa till innehåll"} */}
    </ReachSkipNavLink>
  )
}
