/** @jsx jsx */
import { SkipNavContent as ReachSkipNavContent } from "@reach/skip-nav"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function SkipNavContent({ children }) {
  return (
    <ReachSkipNavContent
      id="main-content"
      sx={{
        flex: 1,
        width: "100%",
      }}
    >
      {children}
    </ReachSkipNavContent>
  )
}

SkipNavContent.propTypes = {
  children: PropTypes.node.isRequired,
}
