/** @jsx jsx */
import { graphql, Link, useStaticQuery } from "gatsby"
import { useMemo } from "react"
import { jsx } from "theme-ui"

import useLocaleContext from "@hooks/useLocaleContext"

export default function LoginButton() {
  const locale = useLocaleContext()

  const data = useStaticQuery(graphql`
    query LoginQuery {
      navigation: allContentfulMainNavigation {
        nodes {
          loginButton {
            href
            icon {
              altText: title
              file {
                url
              }
            }
            slug
            title
          }
          node_locale
        }
      }
    }
  `)

  const nodes = data?.navigation?.nodes || []
  const localizedNavigation = useMemo(
    () => nodes.find(node => node.node_locale === locale),
    [locale, nodes]
  )
  const altText = localizedNavigation?.loginButton?.icon?.altText

  if (localizedNavigation?.loginButton?.slug) {
    return (
      <Link
        sx={{
          variant: "button.secondary",
          alignItems: "center",
          borderColor: "grey.light",
          display: "flex",
          outline: "none",
          paddingX: 6,
          paddingY: 5,
        }}
        to={localizedNavigation?.loginButton?.slug}
      >
        <img
          alt={altText}
          src={localizedNavigation?.loginButton?.icon?.file?.url}
          sx={{
            height: 20,
            marginRight: 4,
            width: 20,
          }}
        />
        <span
          sx={{
            minWidth: "max-content",
            wordBreak: "keep-all",
            textTransform: "uppercase",
            fontSize: 0,
            marginTop: 2,
          }}
        >
          {localizedNavigation?.loginButton?.title}
        </span>
      </Link>
    )
  }

  if (localizedNavigation?.loginButton?.href) {
    const href = localizedNavigation?.loginButton?.href
    return (
      <a
        href={href}
        sx={{
          variant: "button.secondary",
          alignItems: "center",
          borderColor: "grey.light",
          display: "flex",
          outline: "none",
          paddingX: 6,
          paddingY: 5,
        }}
      >
        <img
          alt={altText}
          src={localizedNavigation?.loginButton?.icon?.file?.url}
          sx={{
            height: 20,
            marginRight: 4,
            width: 20,
          }}
        />
        <span
          sx={{
            minWidth: "max-content",
            wordBreak: "keep-all",
            textTransform: "uppercase",
            fontSize: 0,
            marginTop: 2,
          }}
        >
          {localizedNavigation?.loginButton?.title}
        </span>
      </a>
    )
  }

  return null
}
