/** @jsx jsx */
import useSettingsContext from "@src/hooks/useSettingsContext"
import { jsx, Styled } from "theme-ui"

function LogoSmall() {
  const {
    logoSmall: {
      title,
      file: { url },
    },
  } = useSettingsContext()

  return (
    <Styled.img
      alt={title}
      src={url}
      sx={{
        width: "50px",
        height: "50px",
      }}
    />
  )
}
export default LogoSmall
