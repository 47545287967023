/**@jsx jsx */
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, jsx } from "theme-ui"

import useSettingsContext from "@hooks/useSettingsContext"

import Address from "./Address"

const iconLinkStyle = {
  alignSelf: "center",
  fontSize: "20px",
  opacity: 0.6,
  ":hover, :focus": {
    opacity: 1,
  },
}

function Contact() {
  const {
    labelContact,
    phoneNumber,
    phoneNumberLabel,
    emailAddress,
    companyName,
    streetAddress,
    zipCodeCity,
    mapLink,
    facebookLink,
    instagramLink,
    linkedInLink,
  } = useSettingsContext()

  return (
    <Grid gap={10}>
      <Grid gap={6}>
        <span
          sx={{
            color: "white",
            fontSize: [2, 3],

            fontWeight: "bold",
          }}
        >
          {labelContact}
        </span>

        <div
          sx={{
            display: "grid",
            gap: 5,
          }}
        >
          {phoneNumber ? (
            <a
              href={`tel:${phoneNumber}`}
              sx={{
                fontSize: [0, 1],
                color: "white",
                variant: "link",
                opacity: 0.6,
                "&.active, :hover, :focus": {
                  opacity: 1,
                },
              }}
            >
              {phoneNumberLabel || phoneNumber}
            </a>
          ) : null}

          {emailAddress ? (
            <a
              href={`mailto:${emailAddress}`}
              sx={{
                fontSize: [0, 1],
                color: "white",
                variant: "link",
                textDecoration: "underline",
                fontWeight: "bold",

                opacity: 0.6,
                "&.active, :hover, :focus": {
                  opacity: 1,
                },
              }}
            >
              {emailAddress}
            </a>
          ) : null}

          {mapLink ? (
            <a
              href={mapLink}
              rel="noopener noreferrer"
              sx={{
                variant: "link",
                "&.active > div, :hover > div, :focus > div": {
                  opacity: 1,
                },
              }}
              target="_blank"
            >
              <Address
                companyName={companyName}
                streetAddress={streetAddress}
                zipCodeCity={zipCodeCity}
              />
            </a>
          ) : (
            <Address
              companyName={companyName}
              streetAddress={streetAddress}
              zipCodeCity={zipCodeCity}
            />
          )}
        </div>
      </Grid>

      <Grid columns={"repeat( auto-fit, 20px )"} gap={6}>
        {facebookLink ? (
          <a href={facebookLink} sx={iconLinkStyle}>
            <FontAwesomeIcon color="white" icon={faFacebookF} />
          </a>
        ) : null}

        {/* {instagramLink ? (
          <a href={instagramLink} sx={iconLinkStyle}>
            <FontAwesomeIcon color="white" icon={faInstagram} />
          </a>
        ) : null} */}

        {linkedInLink ? (
          <a href={linkedInLink} sx={iconLinkStyle}>
            <FontAwesomeIcon color="white" icon={faLinkedinIn} />
          </a>
        ) : null}
      </Grid>
    </Grid>
  )
}

Contact.propTypes = {}

export default Contact
