export default {
  fonts: {
    body: "Kumbh Sans, sans-serif",
    heading: "Kumbh Sans, serif",
  },
  fontSizes: [
    14, // 0
    16, // 1
    18, // 2
    20, // 3
    24, // 4
    30, // 5
    40, // 6
    56, // 7
    90, // 8
  ],
  fontWeights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
}
