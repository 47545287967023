/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { Fragment, useEffect, useMemo, useState } from "react"
import { Grid, jsx, Styled } from "theme-ui"

import NavLink from "@components/NavLink"
import useLocaleContext from "@hooks/useLocaleContext"
import useTrapFocus from "@hooks/useTrapFocus"

import Hamburger from "./Hamburger"
import LoginButton from "./LoginButton"
import Sidemenu from "./Sidemenu"

import useSettingsContext from "@src/hooks/useSettingsContext"

function MainNav() {
  const [isOpen, setIsOpen] = useState(false)
  const [sidemenuRef, hamburgerRef] = useTrapFocus(isOpen)

  function closeMenu() {
    setIsOpen(false)
  }

  function toggleIsOpen() {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isOpen])

  const data = useStaticQuery(graphql`
    query MainNavQuery {
      navigation: allContentfulMainNavigation {
        nodes {
          title
          node_locale
          items {
            node_locale
            href
            id
            title
            slug
          }
          sidemenu {
            href
            id
            slug
            title
          }
        }
      }
    }
  `)

  const locale = useLocaleContext()

  const nodes = data?.navigation?.nodes || []
  const localizedNavigation = useMemo(
    () => nodes.find(node => node.node_locale === locale),
    [locale, nodes]
  )

  return localizedNavigation ? (
    <Fragment>
      <Grid
        as="ul"
        gap={11}
        sx={{
          alignItems: "center",
          display: ["none", "grid"],
          gridAutoFlow: "column",
          gridAutoColumns: "max-content",
          listStyle: "none",
          m: 0,
          p: 0,
        }}
      >
        {localizedNavigation.items.map(({ href, slug, title, id }) => {
          return (
            <li key={id}>
              <NavLink
                href={href}
                partiallyActive
                sx={{
                  color: "text",
                  fontSize: 3,
                  textDecoration: "none",
                  "&.active": { color: "primary" },
                  ":hover": { color: "primary" },
                }}
                to={slug}
              >
                <Styled.b>{title}</Styled.b>
              </NavLink>
            </li>
          )
        })}
        <li>
          <LoginButton />
        </li>
      </Grid>
      <Hamburger
        handleClick={toggleIsOpen}
        isOpen={isOpen}
        ref={hamburgerRef}
      />
      <Sidemenu
        closeMenu={closeMenu}
        isOpen={isOpen}
        menu={localizedNavigation.sidemenu}
        ref={sidemenuRef}
      />
    </Fragment>
  ) : null
}

export default MainNav
