/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"
import { Grid, jsx } from "theme-ui"

import useLocaleContext from "@hooks/useLocaleContext"

import Contact from "./Contact"
import Section from "./Section"

function FooterNavigation() {
  const {
    navigation: { links },
  } = useStaticQuery(graphql`
    query FooterNavQuery {
      navigation: allContentfulFooterNavigation {
        links: nodes {
          title
          node_locale
          items {
            href
            link
            id
            slug
            title
            links {
              href
              id
              slug
              title
            }
          }
        }
      }
    }
  `)
  const locale = useLocaleContext()
  const localizedNavigation = useMemo(
    () => links.find(node => node.node_locale === locale),
    [links, locale]
  )

  // Create components for traversing nested structures and make it possible to pass UL and LI components

  return localizedNavigation ? (
    <nav>
      <Grid
        as="ul"
        columns={["1fr", "repeat( auto-fit, minmax(100px, 1fr) )"]}
        gap={10}
        sx={{ listStyle: "none", m: 0, p: 0 }}
      >
        {localizedNavigation.items.map(
          ({ href, slug, title, id, links, link }) => {
            return (
              <li key={id}>
                <Section
                  href={href}
                  links={links}
                  slug={slug}
                  title={title}
                  link={link}
                />
              </li>
            )
          }
        )}
        <Contact />
      </Grid>
    </nav>
  ) : null
}

export default FooterNavigation
