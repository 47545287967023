/** @jsx jsx */
import PropTypes from "prop-types"
import { forwardRef } from "react"
import { jsx } from "theme-ui"

const Hamburger = forwardRef(({ handleClick, isOpen }, ref) => {
  return (
    <button
      aria-label="Side Menu"
      className={`${isOpen ? "opened" : ""}`}
      onClick={handleClick}
      ref={ref}
      sx={{
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        display: "flex",
        height: 60,
        mb: 2,
        ml: 10,
        outline: "transparent",
        padding: 0,
        position: "relative",
        width: 60,
        zIndex: 99,

        "&.opened": {
          "> svg > .line1": {
            stroke: "white",
            strokeDasharray: "90 207",
            strokeDashoffset: -134,
          },
          "> svg > .line2": {
            stroke: "transparent",
            strokeDasharray: "1 60",
            strokeDashoffset: -30,
          },
          "> svg > .line3": {
            stroke: "white",
            strokeDasharray: "90 207",
            strokeDashoffset: -134,
          },
        },

        "> svg": {
          height: 60,
          width: 60,
          // margin: "-16px -11px -21px",
          "> .line": {
            fill: "none",
            stroke: "black",
            strokeWidth: 6,
            transition: `stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)`,
          },
          "> .line1": {
            strokeDasharray: "60 207",
          },
          "> .line2": {
            strokeDasharray: "60 60",
          },
          "> .line3": {
            strokeDasharray: "60 207",
          },
        },
      }}
    >
      <svg sx={{ mt: 2 }} viewBox="0 0 100 100">
        <path
          className="line line1"
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          strokeLinecap="round"
        />
        <path className="line line2" d="M 20,45 H 80" strokeLinecap="round" />
        <path
          className="line line3"
          d="M 20,60.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          strokeLinecap="round"
        />
      </svg>
    </button>
  )
})

Hamburger.displayName = "Hamburger"

Hamburger.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default Hamburger
