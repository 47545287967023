import breakpoints from "./breakpoints"
import colors from "./colors"
import fonts from "./fonts"
import space from "./space"
import styles from "./styles"
import variants from "./variants"

export default {
  colors,
  ...fonts,
  breakpoints,
  space,
  styles,
  ...variants,
}
