export default [
  0, // 0
  2, // 1
  4, // 2
  8, // 3
  10, // 4
  16, // 5
  20, // 6
  24, // 7
  30, // 8
  35, // 9
  40, // 10
  50, // 11
  56, // 12
  64, // 13
  75, // 14
  80, // 15
  120, // 16
  140, // 17
  150, // 18
]
